import { FC, useMemo } from 'react';
import FormBuilderBase from '../../components/form-builder/FormBuilderBase';
import { v4 as uuid } from 'uuid';
import { PlatformIcons } from '../../components/shared/icon/IconRender';
import ActionTypes, { ActionTypesInfo } from '../../components/form/ActionTypes';
import ResourceActionEditor from '../../components/form-builder/ResourceActionEditor';
import ResourceSectionPropertiesEditor from '../../components/form-builder/ResourceSectionPropertiesEditor';
import ResourcePropertiesEditor from '../../components/form-builder/ResourcePropertiesEditor';
import { FormType } from '../../models/FormTypes';
import { useRecoilValue } from 'recoil';
import { currentClientAtom, currentTenantIdAtom } from '../../recoil/atoms/Clients';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from './Layout';

const ResourceBuilder: FC = () => {
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const { t } = useTranslation('form-builder');

  const actionTypes = useMemo(() => {
    const result: Record<string, ActionTypesInfo> = {};

    for (const type of Object.keys(ActionTypes)) {
      if (ActionTypes[type].resourceFriendly) {
        result[type] = ActionTypes[type];
      }
    }

    return result;
  }, []);

  return (
    <AdminLayout>
      <FormBuilderBase
        initialForm={{
          id: uuid(),
          code: '',
          version: 1,
          iconCode: PlatformIcons.FORM,
          translations: {},
          sections: [],
          type: FormType.Resource,
          placeholders: [],
        }}
        topNavHeadingKey="form-builder:heading.resource-builder"
        hidePreview
        actionTypes={actionTypes}
        failsafeKey="resource"
        PropertiesEditors={{
          ActionPropertiesEditor: ResourceActionEditor,
          SectionPropertiesEditor: ResourceSectionPropertiesEditor,
          FormPropertiesEditor: ResourcePropertiesEditor,
        }}
        breadCrumbs={[
          {
            name: t('breadcrumb.resource-builder'),
            path: currentClient?.id ? `/clients/${currentClient.id}/resources` : `/builder/${currentTenantId}#resource-builder`,
          },
        ]}
      />
    </AdminLayout>
  );
};

export default ResourceBuilder;
