import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IconPicker from '../shared/form-control/IconPicker';
import { PlatformIcons } from '../shared/icon/IconRender';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { FormPropertiesProps } from './FormBuilderTypes';
import TranslatableInput from '../shared/form-control/TranslatableInput';

const ResourcePropertiesEditor: FC<FormPropertiesProps> = (props) => {
  const { form, patchFormConfig } = props;
  const { iconCode } = form;
  const { t } = useTranslation('form-builder');

  const iconText = useMemo(
    () =>
      iconCode
        ?.replace(/Icon$/, '')
        .match(/[A-Z][a-z]+/g)
        ?.join(' ') || '',
    [iconCode],
  );

  return (
    <div data-cy="resource-properties">
      <Heading size={HeadingSize.H4}>{t('resource-properties.heading')}</Heading>

      <TranslatableInput
        data-cy="form-title"
        label={t('resource-properties.form-title')}
        placeholder={t('resource-properties.form-title')}
        translationKey="title"
        translations={form.translations}
        onTranslationsChange={(translations) => patchFormConfig({ translations })}
        autoFocus
        maxLength={100}
      />

      <div className="text-dpm-12 mt-4" data-cy="en-form-icon">
        {t('resource-properties.form-icon')}
      </div>
      <IconPicker
        data-cy="form-icon"
        options={Object.values(PlatformIcons).map((iconValue) => ({ id: iconValue, text: iconValue, value: iconValue }))}
        placeholder={t('form-properties.form-title')}
        value={{ id: iconCode, text: iconText, value: iconCode }}
        onChange={(option) => patchFormConfig({ iconCode: option.value.toString() })}
      />
    </div>
  );
};

export default ResourcePropertiesEditor;
