import { FC } from 'react';
import Checkbox from '../shared/form-control/Checkbox';
import InfoIcon from '../shared/icon/InfoIcon';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Tooltip from '../shared/Tooltip';
import { SectionPropertiesProps } from './FormBuilderTypes';
import { useTranslation } from 'react-i18next';
import TranslatableInput from '../shared/form-control/TranslatableInput';
import LanguageUtils from '../../utils/LanguageUtils';

const ResourceSectionPropertiesEditor: FC<SectionPropertiesProps> = (props) => {
  const { section, patchSection } = props;
  const { isDraft, translations } = section;

  const { t } = useTranslation('form-builder');

  const onIsDraftChange = (value: boolean) => {
    if (value && section.isPublic) {
      patchSection({ isDraft: value, isPublic: false });
    } else {
      patchSection({ isDraft: value });
    }
  };

  return (
    <div data-cy="section-properties">
      <Heading size={HeadingSize.H4}>
        {t('resource-section-properties.heading', {
          title: LanguageUtils.getTranslation('title', translations) || t('resource-section-properties.heading-fallback'),
        })}
      </Heading>

      <TranslatableInput
        data-cy="section-title"
        placeholder={t('resource-section-properties.section-title')}
        label={t('resource-section-properties.section-title')}
        autoFocus
        translationKey="title"
        translations={translations}
        onTranslationsChange={(translations) => patchSection({ translations })}
      />

      <Tooltip text={<div className="max-w-lg">{t('resource-section-properties.draft-help')}</div>}>
        {(tooltip) => (
          <div className="flex items-center gap-4">
            <Checkbox value={isDraft} onChange={onIsDraftChange} label={t('resource-section-properties.draft')} />
            <div {...tooltip}>
              <InfoIcon className="h-6 w-6" />
            </div>
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export default ResourceSectionPropertiesEditor;
